import { iActivity } from "@sdk/crm/crm.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { activityDefaultQueryConfig } from "./activities-default-query";

export const {
  slice: ActivitiesSlice,
  reducers: ActivitiesSliceReducer,
  actions: {
    setEntityQueryResults: setActivitiesQueryResults,
    setEntity: setActivity,
    setEntities: setActivities,
    patchEntity: patchActivity,
    addNewEntity: addNewActivity,
    setEntityFetchingStatus: setActivityFetchingStatus,
    setEntitiesFetchingStatus: setActivitiesFetchingStatus,
    setEntityFailedStatus: setActivityFailedStatus,
    setEntityQueryError: setActivitiesQueryError,
    resetQuery: resetActivitiesQuery,
    setQueryList: setActivityQueryList,
  },
} = CreateEntitySlice<iActivity>({
  sliceName: "activities",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...activityDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
