export const GlobalConfig = (() => {
  // const isProd =
  //   window.location.href.indexOf("help-desk-genie.web.app") > -1 ||
  //   window.location.href.indexOf("web.clickconnector.app") > -1 ||
  //   window.location.href.indexOf("clickconnector.io") > -1 ||
  //   window.location.href.indexOf("vercel.app") > -1;

  const isProd = true;
  if (isProd) {
    return {
      IS_PROD: true,
      ENGINE_BASE_PATH: "https://engine.clickconnector.app/api/v1",
      SOCKET_SERVER: "https://engine.clickconnector.app/",
      availableDomains: ["http://localhost:8041"],
      HELP_DESK_EMAIL_DOMAIN: "helpdesk.clickconnector.com",
      // Prod
      FACEBOOK_APP_ID: 363255511253068,
      STRIPE_KEY:
        "pk_live_51HRjJUJ7lCgvK4GX0rtRvKBVs6llu9UXppCDJ6Su9TYPAeMRcLF7ujDSx9TlEnRwAynCJD1EIBGo7d0MtdC0yilK005jjqK0e3",
      CHAT_WIDGET_PREVIEW: "https://widget.clickconnector.app/preview/",
      CHAT_WIDGET_BASE: "https://widget.clickconnector.app",
      COMMON_WIDGET_BASE: "https://extension-widgets.clickconnector.app",
      AUTH_APP_URL: "https://auth.clickconnector.io/external-auth-connector",
      UNSPLASH_API_KEY: "xIelu3VTxFdS-OvolI_0L9BMFNJZj-6RehKbdkWV9a4",
    };
  }
  return {
    IS_PROD: false,
    ENGINE_BASE_PATH: "http://localhost:7567/api/v1",
    SOCKET_SERVER: "http://localhost:7567/",
    // ENGINE_BASE_PATH: "http://192.168.1.57:7567/api/v1",
    // SOCKET_SERVER: "http://192.168.1.57:7567/",
    availableDomains: ["http://localhost:8041"],
    HELP_DESK_EMAIL_DOMAIN: "helpdesk.clickconnector.com",
    // Edge
    FACEBOOK_APP_ID: 179131883892188,
    STRIPE_KEY:
      "pk_test_51IxT3jBsojgwyHBDgcbJYCCu4gGUUSLf48FSFk7wzBmmGgqSdVnJmG8p5cWZdy6LFLw9vXwigKaOPKf1h2Pan48r00vNmrNGza",
    CHAT_WIDGET_PREVIEW: "http://localhost:9000/preview-local/",
    CHAT_WIDGET_BASE: "http://localhost:9000",
    COMMON_WIDGET_BASE: "http://localhost:9001",
    AUTH_APP_URL: "https://auth.clickconnector.io/external-auth-connector",
    // AUTH_APP_URL: "http://localhost:3000/external-auth-connector",
    UNSPLASH_API_KEY: "xIelu3VTxFdS-OvolI_0L9BMFNJZj-6RehKbdkWV9a4",
  };
})();
