import { buildChatViewQueryConfigs } from "components/modules/conversations/helpers/build-chat-view-queries";
import { combineEpics } from "redux-observable";
import { createEntityStateListMaintainerEpic } from "./epics/build-entity-list-state-maintainer-epic";
// import { conversationListStateMaintainerEpic } from "./epics/conversations/conversation-list-state-maintainer.epic";
import { notificationsEffects } from "./epics/notifications/notifications-emitter.epic";
// import { fbPostListStateMaintainerEpic } from "./epics/posts/posts-list-state-maintainer.epic";
// import { taskListStateMaintainerEpic } from "./epics/tasks/tasks-list-state-maintainer.epic";
import { userPresenceLoader } from "./epics/users/user-presence-loader.epic";
import { activityDefaultQueryConfig } from "./modules/activities/activities-default-query";
import {
  selectActivityById,
  selectActivityQueryMap,
} from "./modules/activities/activities.selectors";
import { setActivityQueryList } from "./modules/activities/activities.slice";
import { articleDefaultQueryConfig } from "./modules/articles/article-default-query";
import {
  selectArticleById,
  selectArticleQueryMap,
} from "./modules/articles/articles.selectors";
import { setArticlesQueryList } from "./modules/articles/articles.slice";
import { contactsDefaultQueryConfig } from "./modules/contacts/contacts-default-query";
import {
  selectContactById,
  selectContactQueryMap,
} from "./modules/contacts/contacts.selectors";
import { setContactsQueryList } from "./modules/contacts/contacts.slice";
import {
  selectConversationById,
  selectConversationQueryMap,
} from "./modules/conversations/conversations.selectors";
import { setConversationQueryList } from "./modules/conversations/conversations.slice";
import { fbPostsDefaultQueryConfig } from "./modules/fb-posts/fb-posts-default-query";
import {
  selectFbPostById,
  selectFbPostQueryMap,
} from "./modules/fb-posts/fb-posts.selectors";
import { setFbPostQueryList } from "./modules/fb-posts/fb-posts.slice";
import { opportunitiesDefaultQueryConfig } from "./modules/opportunities/opportunities-default-query";
import {
  selectOpportunityById,
  selectOpportunityQueryMap,
} from "./modules/opportunities/opportunities.selectors";
import { setOpportunitiesQueryList } from "./modules/opportunities/opportunities.slice";
import { tasksDefaultQueryConfig } from "./modules/tasks/tasks-default-query";
import {
  selectTaskById,
  selectTaskQueryMap,
} from "./modules/tasks/tasks.selectors";
import { setTaskQueryList } from "./modules/tasks/tasks.slice";
import { ticketsDefaultQueryConfig } from "./modules/tickets/tickets-default-query";
import {
  selectTicketById,
  selectTicketQueryMap,
} from "./modules/tickets/tickets.selectors";
import { setTicketQueryList } from "./modules/tickets/tickets.slice";

const conversationListStateMaintainerEpic = createEntityStateListMaintainerEpic(
  {
    entity: "conversations",
    selectEntityById: selectConversationById,
    selectEntityQueryMap: selectConversationQueryMap,
    setEntityQueryList: setConversationQueryList,
    otherAliasBuilder: buildChatViewQueryConfigs,
  },
);

const contactsListStateMaintainerEpic = createEntityStateListMaintainerEpic({
  entity: "contacts",
  selectEntityById: selectContactById,
  selectEntityQueryMap: selectContactQueryMap,
  setEntityQueryList: setContactsQueryList,
  otherAliasBuilder: () => ({
    all: contactsDefaultQueryConfig,
  }),
});

const fbPostListStateMaintainerEpic = createEntityStateListMaintainerEpic({
  entity: "fbPosts",
  selectEntityById: selectFbPostById,
  selectEntityQueryMap: selectFbPostQueryMap,
  setEntityQueryList: setFbPostQueryList,
  otherAliasBuilder: () => ({
    all: fbPostsDefaultQueryConfig,
  }),
});

const taskListStateMaintainerEpic = createEntityStateListMaintainerEpic({
  entity: "tasks",
  selectEntityById: selectTaskById,
  selectEntityQueryMap: selectTaskQueryMap,
  setEntityQueryList: setTaskQueryList,
  otherAliasBuilder: () => ({
    all: tasksDefaultQueryConfig,
  }),
});

const activitiesListStateMaintainerEpic = createEntityStateListMaintainerEpic({
  entity: "activities",
  selectEntityById: selectActivityById,
  selectEntityQueryMap: selectActivityQueryMap,
  setEntityQueryList: setActivityQueryList,
  otherAliasBuilder: () => ({
    all: activityDefaultQueryConfig,
  }),
});

const opportunitiesListStateMaintainerEpic =
  createEntityStateListMaintainerEpic({
    entity: "opportunities",
    selectEntityById: selectOpportunityById,
    selectEntityQueryMap: selectOpportunityQueryMap,
    setEntityQueryList: setOpportunitiesQueryList,
    otherAliasBuilder: () => ({
      all: opportunitiesDefaultQueryConfig,
    }),
  });

const articlesListStateMaintainerEpic = createEntityStateListMaintainerEpic({
  entity: "articles",
  selectEntityById: selectArticleById,
  selectEntityQueryMap: selectArticleQueryMap,
  setEntityQueryList: setArticlesQueryList,
  otherAliasBuilder: () => ({
    all: articleDefaultQueryConfig,
  }),
});

const ticketListStateMaintainerEpic = createEntityStateListMaintainerEpic({
  entity: "tickets",
  selectEntityById: selectTicketById,
  selectEntityQueryMap: selectTicketQueryMap,
  setEntityQueryList: setTicketQueryList,
  otherAliasBuilder: () => ({
    all: ticketsDefaultQueryConfig,
  }),
});

export default combineEpics(
  conversationListStateMaintainerEpic,
  contactsListStateMaintainerEpic,
  fbPostListStateMaintainerEpic,
  userPresenceLoader,
  notificationsEffects,
  taskListStateMaintainerEpic,
  activitiesListStateMaintainerEpic,
  opportunitiesListStateMaintainerEpic,
  articlesListStateMaintainerEpic,
  ticketListStateMaintainerEpic,
);
