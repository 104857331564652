import { SDK } from "@sdk";
import { iActivity } from "@sdk/crm/crm.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { activityDefaultQueryConfig } from "./activities-default-query";
import { ActivitiesSlice } from "./activities.slice";

export const loadAllActivities = (
  store: Store<iStore>,
  forceReload?: boolean,
) => loadActivitiesQuery(activityDefaultQueryConfig, "all")(store, forceReload);

export const loadAllActivitiesOfContact = (contactId: string) => {
  const queryConfig = {
    ...activityDefaultQueryConfig,
    query: {
      contactId: contactId,
    },
  };
  return loadActivitiesQuery(queryConfig, JSON.stringify(queryConfig));
};

export const loadAllActivitiesOfUser = (userId: string) => {
  const queryConfig = {
    ...activityDefaultQueryConfig,
    query: {
      owner: userId,
    },
  };
  return loadActivitiesQuery(queryConfig, JSON.stringify(queryConfig));
};

export const {
  loadEntityQueries: loadActivitiesQuery,
  loadEntityById: loadActivityById,
  loadEntitiesByIds: bulkLoadActivitiesByIds,
} = CreateHelpersForEntity<iStore, iActivity>({
  sliceName: "activities",
  slice: ActivitiesSlice,
  isPaginatedQueries: true,
  loadEntityQueryProvider: (query) => SDK.activities.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.activities.getById(entityId),
  loadEntityByIdsProvider: (activityIds: string[]) =>
    (async () => {
      const activities = await Promise.all(
        activityIds.map((activityId) => SDK.activities.getById(activityId)),
      );
      return activities;
    })(),
});
